<template>
  <div class="mt-12">
    <div class="card bg-white rounded-xl p-4 ">
      <div class="relative">
        <div class="card top_primary_card bg-primary font-bold text-white p-4 rounded-xl absolute -top-11 w-full">
          Location
        </div>
      </div>
      <div class="mt-8">
        <div class="flex overflow-auto mb-2 items-center mt-4">
          <div class="pr-2 text-gray4 heading-5">Employee:</div>
          <div class="" v-for="(status, index) in empStatusList" :key="index" :class="`${index === 0 ? 'left-0 sticky bg-white' : ''}`">
            <StatusBtn class=" whitespace-nowrap my-1" @buttonAction="toggleActiveAndInactiveB(index)" :selected="status.selected" :btnText="status.fullName" :lastInlist="empStatusList.length === index + 1"/>     
          </div>
        </div>
      </div>
      <div class="card bg-white rounded-xl p-2 mt-4">
       <div class="relative">
         <gmap-map
         ref="gmap"
           :center="center"
           :zoom="11"
           style="width: 100%; height: 700px">
           <gmap-marker :position="center" :clickable="true">
             <gmap-custom-marker v-for="(marker, index) in userLocation" :key="index"
             :marker="{latitude: marker.locationObj.latitude, longitude: marker.locationObj.longitude}">
               <div class="staff-popup card rounded bg-white">
                 <p class="text-text2 heading-6">{{marker.firstName}} {{marker.lastName}}</p>
                 <p class="text-text2">{{marker.addedDate | filterForLocationTime}}</p>
               </div>
             </gmap-custom-marker>
           </gmap-marker>
         </gmap-map>
       </div>
     </div>
    </div>
  </div>
</template>

<script>
import * as config from '@/config.js'
import GmapCustomMarker from './vue2-gmap-custom-marker/gmap-custom-marker.vue'
import store from '@/store'
import Vue from 'vue'
import * as VueGoogleMaps from 'vue2-google-maps'
import StatusBtn from "@/View/components/statu-filter-btn.vue";
import MyJobApp from '@/api/MyJobApp.js'
Vue.use(VueGoogleMaps, {
  load: {
    key: store.getters.googleMapApiKey,
    libraries: 'places,drawing' // necessary for places input
  }
})
export default {
  props: ['markers'],
  name: 'GoogleMap',
  components: {
    StatusBtn,
    'gmap-custom-marker': GmapCustomMarker,
  },
  data () {
    return {
      orgId: 0,
      isSocketLoaded: false,
      userLocation: [],
      empStatusList: [],
      selectedEmpId: [],
      center: { lat: 18.60215033927151, lng: 73.75374753105622},
      // markers: [],
      places: [],
      currentPlace: null
    }
  },
  created () {
  },
  mounted () {
    this.getEmployeeList()
    this.getActivityList()

    let orgDetail = JSON.parse(localStorage.getItem('orgInfo'))
    let jobUserInfo = JSON.parse(localStorage.getItem('jobUserInfo'))
    this.orgId = jobUserInfo.organizationDetailId
    if (orgDetail !== null) {
      this.center = { lat: orgDetail.latitude, lng: orgDetail.longitude}
    } else {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(currentPosition => {
          this.center = {
            lat : currentPosition.coords.latitude,
            lng : currentPosition.coords.longitude,
          }
        })
      }
      else {
        alert('Geolocation is not supported in this browser')
      }
    }
  },
  beforeDestroy () {
    if (this.socket) {
      this.socket.onclose = function () {}
      this.socket.close()
    }
  },
  methods: {
    toggleActiveAndInactiveB (index) {
      let filter = []
      if (this.empStatusList[index].fullName.toLowerCase() === 'all') {
        this.empStatusList[0].selected = true
        this.selectedEmpId = []
        for (let index = 0; index < this.empStatusList.length; index++) {
          this.empStatusList[index].selected = true
        }
      } else {
        this.empStatusList[index].selected = !this.empStatusList[index].selected
        this.selectedEmpId = []
        this.empStatusList.forEach((a, id) => {
          if (this.empStatusList[0].selected) {
            if (id !== index && id !== 0) {
              a.selected = false
            } else {
              if (id !== 0) {
                a.selected = true
                this.selectedEmpId.push(a.userDetailId)
              }
            }
          } else {
            if (a.selected) {
              filter.push(a)
              this.selectedEmpId.push(a.userDetailId)
            }
          }
        })
        this.empStatusList[0].selected = false
      }
      if (filter.length === this.empStatusList.length - 1) {
        this.empStatusList[0].selected = true
        this.selectedEmpId = []
        for (let index = 0; index < this.empStatusList.length; index++) {
          this.empStatusList[index].selected = true
        }
      }
      let arry = this.empStatusList.filter(a => {
        return a.selected === false
      })
      if (arry.length === this.empStatusList.length) {
        this.empStatusList[0].selected = true
        this.selectedEmpId = []
        for (let index = 0; index < this.empStatusList.length; index++) {
          this.empStatusList[index].selected = true
        }
      }
      this.getActivityList()
    },
    getActivityList (noBound) {
      MyJobApp.getUserLocation(
        this.selectedEmpId,
        response => {
          let temp = response.Data !== null ? response.Data : []
          for (let index = 0; index < temp.length; index++) {
            temp[index].locationObj = JSON.parse(temp[index].locationObj)
          }
          this.userLocation = temp
          if (!noBound) {
            setTimeout(() => {
            this.setBound()
          }, 100)
          }
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        () => {
          this.resetPaginationValues = false
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        }
      )
    },
    setBound () {
      this.$refs.gmap.$mapPromise.then((map) => {
        let fitBoud = false
        const bounds = new window.google.maps.LatLngBounds()
        let position = {
          lat: parseFloat(18.60215033927151),
          lng: parseFloat(73.75374753105622)
        }
        bounds.extend(position)
        for (let m of this.userLocation) {
          if (parseFloat(m.locationObj.latitude) !== 0) {
            fitBoud = true
            let position = {lat: parseFloat(m.locationObj.latitude), lng: parseFloat(m.locationObj.longitude)}
            bounds.extend(position)
          }
        }
        if (fitBoud === true) {
          map.fitBounds(bounds)
        }
      })
    },
    getEmployeeList () {
      MyJobApp.getEmployeeList(
        'active',
        response => {
          let temp = response.Data.tableRow !== null ? response.Data.tableRow : []
          for (let index = 0; index < temp.length; index++) {
            temp[index].selected = true
            temp[index].fullName = temp[index].firstName + temp[index].lastName.substr(0, 1)
          }
          const newFirstElement = {
            userDetailId: 0,
            fullName: "All",
            selected: true
          }
          this.empStatusList = [newFirstElement].concat(temp)
          if (!this.isSocketLoaded) {
            this.loadSocket()
          }
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        () => {
          this.resetPaginationValues = false
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        }
      )
    },
    loadSocket () {
      this.isSocketLoaded = true
      this.socket = new WebSocket(config.SOCKET_API + '/api/v1/notify/ws')
      this.socket.onopen = () => {
            let data = 'org-' + this.orgId
        this.socket.send(data)
        this.socket.onerror = (error) => {
          console.error('WebSocket error:', error);
        };
        this.socket.onmessage = ({data}) => {
          let receivedData = JSON.parse(data)
          let slug = receivedData.data.communication_type_slug
          if (slug === 'job') {
            console.log('job slug load');
          } else if (slug === 'location') {
            // console.log('check 22 receivedData', receivedData);
            //   this.updateStaffLocation(receivedData)
            this.getActivityList(true)
          }
        }
      }
    },
    updateStaffLocation (receivedData) {
      let notifyObj = receivedData.data.notify_object
      console.log('notifyObj', notifyObj)
      for (let index = 0; index < this.userLocation.length; index++) {
        let staffLocation = this.userLocation[index]        
        if (staffLocation.userDetailId === receivedData.data.user_detail_id) {          
          this.userLocation[index].addedDate = receivedData.data.expire_on +'Z'
          this.userLocation[index].locationObj.latitude = notifyObj.latitude
          this.userLocation[index].locationObj.longitude = notifyObj.longitude
        }
      }
    },
  }
}
</script>
<style scoped>
.staff-popup {
    -webkit-transition: none!important;
    transition: none!important;
    color: #000;
    background-color: #ffffff;
    border-color: #626f80;
    width: 160px;
    min-width: 38px;
    border-radius: 12px;
    padding: 4px 8px 4px 12px;
    position: absolute;
    border-width: 2px;
    border-style: solid;
    height: auto;
    -webkit-transition: .3s all cubic-bezier(.39,.575,.565,1);
    transition: .3s all cubic-bezier(.39,.575,.565,1);
    font-size: 13px;
    text-align: center;
    left: -57px;
    top: -45px;
}
.staff-popup img {
    height: 35px;
    border-radius: 50%;
}
.staff-popup:before {
  background-color: inherit;
  border-color: inherit;
  display: inline-block;
  margin: 0;
  content: '';
  width: 10px;
  height: 10px;
  position: absolute;
  top: 36px;
  left: calc(50% - 5px);
  border-width: 0 2px 2px 0;
  border-style: solid;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
</style>